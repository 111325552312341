import GATSBY_COMPILED_MDX from "/home/aurora/projects/gatsby/gatsby-website/Personal-Website-Gatsby/src/articles/website-details.md";
import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import "../styles/ArticleStyles.css";
import {GatsbyImage} from "gatsby-plugin-image";
import SEO from "../components/seo";
function ArticleTemplate({data: {mdx}, children}) {
  return React.createElement("main", null, React.createElement(SEO, {
    title: mdx.frontmatter.title,
    description: mdx.frontmatter.description
  }), React.createElement(Layout, null, React.createElement("div", {
    className: "blog-post-container"
  }, React.createElement("div", {
    className: "blog-post"
  }, React.createElement("h1", null, mdx.frontmatter.title), React.createElement("div", {
    className: "post-featured-image-section"
  }, React.createElement(GatsbyImage, {
    image: mdx.frontmatter.featured_image.childImageSharp.gatsbyImageData,
    height: 600,
    width: 600,
    alt: mdx.frontmatter.title
  }), React.createElement("div", {
    className: "post-info-container"
  }, React.createElement("small", {
    className: "post-info-item"
  }, "By ", mdx.frontmatter.author), React.createElement("small", {
    className: "post-info-divider"
  }, "|"), React.createElement("small", {
    className: "post-info-item"
  }, " Published ", mdx.frontmatter.date_published), React.createElement("small", {
    className: "post-info-divider"
  }, "|"), React.createElement("small", {
    className: "post-info-item"
  }, " Updated ", mdx.frontmatter.last_modified), React.createElement("small", {
    className: "post-info-divider"
  }, "|"), React.createElement("small", {
    className: "post-info-item"
  }, "Tags: ", mdx.frontmatter.tags.join(", ")))), React.createElement("div", {
    className: "blog-post-content"
  }, children)))));
}
export const Head = ({data: {mdx: {frontmatter: {title, description}}}}) => {
  return React.createElement(SEO, {
    title: title,
    description: description
  });
};
export const pageQuery = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
      frontmatter {
        date_published(formatString: "MMMM DD, YYYY")
        last_modified(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        tags
        description
        featured_image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1200
              height: 800
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
  }
`;
ArticleTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ArticleTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
